import request from '@/axios'
import {
  ApiSetting,
  ChannelLevel,
  FinancialSetting,
  MailSetting,
  PartnerLevel,
  PaymentAlipay,
  PaymentConfig,
  PaymentWx,
  PlatformConfig,
  ShareLevel,
  SmsSetting,
  VipSetting,
  WithdrawSetting
} from '@/api/setting/types'

export const platformConfig = (): Promise<IResponse<PlatformConfig>> => {
  return request.post({ url: '/api/v1/ams/platform/config' })
}

export const shareLevel = (): Promise<IResponse<Records<ShareLevel>>> => {
  return request.post({ url: '/api/v1/ams/setting/share/level' })
}

export const saveShareLevel = (data: ShareLevel): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/share/level/save', data })
}

export const partnerLevel = (): Promise<IResponse<Records<PartnerLevel>>> => {
  return request.post({ url: '/api/v1/ams/setting/partner/level' })
}

export const savePartnerLevel = (data: PartnerLevel): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/partner/level/save', data })
}

export const channelLevel = (): Promise<IResponse<Records<ChannelLevel>>> => {
  return request.post({ url: '/api/v1/ams/setting/channel/level' })
}

export const saveChannelLevel = (data: ChannelLevel): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/channel/level/save', data })
}

export const getVipSetting = (): Promise<IResponse<VipSetting>> => {
  return request.post({ url: '/api/v1/ams/setting/vip' })
}

export const saveVipSetting = (data: VipSetting): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/vip/save', data })
}

export const getWithdrawSetting = (): Promise<IResponse<WithdrawSetting>> => {
  return request.post({ url: '/api/v1/ams/setting/withdraw' })
}

export const saveWithdrawSetting = (data: WithdrawSetting): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/withdraw/save', data })
}

export const getFinancialSetting = (): Promise<IResponse<FinancialSetting>> => {
  return request.post({ url: '/api/v1/ams/setting/financial' })
}

export const saveFinancialSetting = (data: FinancialSetting): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/financial/save', data })
}

export const getApiSetting = (): Promise<IResponse<ApiSetting>> => {
  return request.post({ url: '/api/v1/ams/setting/api' })
}

export const saveSmsSetting = (data: SmsSetting): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/api/sms', data })
}

export const saveMailSetting = (data: MailSetting): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/api/mail', data })
}

export const savePaymentWx = (data: PaymentWx): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/api/payment/wx', data })
}

export const savePaymentAlipay = (data: PaymentAlipay): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/api/payment/alipay', data })
}

export const paymentConfig = (): Promise<IResponse<PaymentConfig>> => {
  return request.post({ url: '/api/v1/ams/setting/financial/payment' })
}

export const savePaymentConfig = (data: PaymentConfig): Promise<IResponse<any>> => {
  return request.post({ url: '/api/v1/ams/setting/financial/payment/save', data })
}
